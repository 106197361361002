.container {
  padding: 0;
  margin: 0;
  text-align: center;
  background-color: #eeeeee;
  height: 100vh;
  width: 100vw;
  padding: 40px 100px 40px 100px !important;
  box-sizing: border-box;
  justify-items: stretch;
  justify-content: stretch;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 80px 1fr 3fr 50px;
  grid-template-areas:
    "title mail"
    "template mail"
    "translatee mail"
    "save viewportt";
  grid-gap: 50px 20px;






  &__save {
    grid-area: save;
    // width: 560px;
    align-self: end;
    text-align: left;
    justify-self: start;
    // overflow: auto;
  }

  &__drop_translation {
    // overflow: auto;
    grid-area: translatee;
    width: 85%;
    height: 100%;
    place-self: center;
    // height: 100%;
  }

  &__drop_file {
    // overflow: auto;
    grid-area: template;
    width: 85%;
    height: 100%;
    place-self: center;
    // width: 700px;
    // place-self: center;
  }

  &__title {
    // overflow: auto;
    width: 360px;
    grid-area: title;
    // padding-top: 20px;
    // padding-bottom: 20px;
    // justify-self: start;
  }

  &__mail {
    // overflow: auto;
    // width: 700px;
    grid-area: mail;
    // height: 90vh;
    place-self: center;
    // align-self: end;
  }


  &__viewport {
    place-self: center;
    grid-area: viewportt;
    // place-self: center;
    // overflow: auto;
    // text-align: left;
    align-self: start;
  }


}